:root {
  --black: 24, 24, 24;
  --white: 246, 246, 246;
  --yellow: 241, 158, 56;
    --red: 196, 0, 0;
}

html {
  background: rgb(24, 24, 24);
  color: rgba(var(--white), 0.5);
}

::-webkit-scrollbar {
  width: 0px;
}
.fade {
  opacity: 0;
  transition: all 0.6s !important;
  transform: translateY(200px);
}

.fade.in {
  opacity: 1;
  transform: none;
}

#grad{
  background: linear-gradient(180deg, #191919 7%, rgba(24,24,24, 0) 79.8%);
  z-index:10;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:15%;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  #blur {
    background: linear-gradient(180deg, #191919 0%, rgba(24,24,24, 0) 79.8%);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    z-index:10;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:4%;
  }
}


#logo {
  position:relative;
  background-color: #1F1F1F;
  width:100%;
  height:300px;
}

.logo{
  width:50%;
  position: absolute;
  left: 8.69%;
  right: 9.49%;
  top: 6.81%;
  bottom: 8.13%;
}

#ordo{
  height:550px;
}

.banner{
  height:254px;
  width:100%;
}

.react-draggable {
  display:block;
  position:relative;
  z-index:100;
}

.draggable-wrapper{
  display: block;
  float:right;
  margin:0 20px 20px 0;
}

.handle{
  padding:7px;
  vertical-align: middle;
  cursor: crosshair;
  color: rgb(var(--white));
}

.white .handle {
  background: rgba(75, 75, 75, 0.5);
}

.red .handle {
  background: rgba(var(--red), 0.5);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .handleblur {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
}

.popup-title{
  display: block;
  text-align: left;
  margin-left:10px;
}

.popup-x{
  position:absolute;
  right:10px;
  top:7px;
  display: block;
  cursor: pointer;
}

body{
  background: rgb(24, 24, 24);
  font-family: 'VT323', monospace;
  font-style: normal;
  font-weight: normal;
  font-size:22px !important;
  line-height:22px !important;
  color: rgba(var(--white), 0.5);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}




/* tab bar */
.terminal-base .lfnIny {
  display:none !important;
}



#root{
  padding:0;
  margin:0;
}

.svgs {
  margin:0 0 22%;
}
